<template>
  <div>
    <v-form>
      <p class="mb-4">
        Use the drop-down menus to label the given statements as <b>True or False</b>.
      </p>
      <v-simple-table>
        <thead>
          <tr>
            <td>a) Sodium hydroxide pellets can cause severe damage to skin and eyes.</td>
            <td>
              <v-select v-model="inputs.input1" :items="items" label="Select Option" />
            </td>
          </tr>

          <tr>
            <td>
              b) Lachrymators like benzyl bromide are specifically dangerous because of their
              explosion hazard.
            </td>
            <td>
              <v-select v-model="inputs.input2" :items="items" label="Select Option" />
            </td>
          </tr>

          <tr>
            <td>c) 1-bromobutane must be used only in the fume hood.</td>
            <td>
              <v-select v-model="inputs.input3" :items="items" label="Select Option" />
            </td>
          </tr>

          <tr>
            <td>d) Unused 1-bromobutane can be disposed of in the aqueous waste.</td>
            <td>
              <v-select v-model="inputs.input4" :items="items" label="Select Option" />
            </td>
          </tr>

          <tr>
            <td>e) Gloves and goggles are not required when handling sodium hydroxide.</td>
            <td>
              <v-select v-model="inputs.input5" :items="items" label="Select Option" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemUCI51LBA4Q8',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items: [
        {text: 'True', value: 'true'},
        {text: 'False', value: 'false'},
      ],
    };
  },
};
</script>
